import { render, staticRenderFns } from "./navbar.vue?vue&type=template&id=386a917e&scoped=true&"
import script from "./navbar.vue?vue&type=script&lang=js&"
export * from "./navbar.vue?vue&type=script&lang=js&"
import style0 from "./navbar.vue?vue&type=style&index=0&id=386a917e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "386a917e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VIcon,VList,VListItem,VMenu})
