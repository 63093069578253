<script>
  import {appConfig} from '@src/config';
  export default {
    components: {},
    props: {
      isfake: {
        type: Boolean,
        default: false,
      },
      disableContext: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        appConfig: appConfig
      }
    },
    methods: {
      toHash(path, hash) {
        // this.$router.push({ to: path, hash: hash });
      },
      toRouter(path) {

      },

      toLink(redirect) {
        window.open(redirect, 'target_blank');
      },
    }
  }
</script>

<template>
  <div class="app-footer--home">
    <div class="footer-container">

      <div class="footer--wrapper">

        <div class="section--enterprise">

          <div class="enterprise--brand">
            <RouterLink class="encapsuled-link" to="/" v-bind="$attrs">
              <img class="image-brand" :src="require('@assets/home/logo-horizontal@3x.png')" alt="logo" />
            </RouterLink>
          </div>

          <!-- <div class="enterprise-address m-b-8">
          <p class="text-caption text-bold-thin text-gray">
            Rua Dr. Mario Gonçalves, 213/01 <br>
            Chácara Cachoeira, Campo Grande <br>
            79040-861 - MS
            </p>
          </div> -->

          <div class="enterprise-midias">
            <span class="text-caption text-bold-thin text-black">Siga a gente:</span>

            <div class="midias-content">
              <VBtn v-if="appConfig.links.linkedin" class="midias-item" small icon @click="toLink(appConfig.links.linkedin.link)">
                <VIcon color="primary">fab fa-linkedin-in</VIcon>
              </VBtn>

              <VBtn v-if="appConfig.links.facebook" class="midias-item" small icon @click="toLink(appConfig.links.facebook.link)">
                <VIcon color="primary" small>fab fa-facebook</VIcon>
              </VBtn>

              <VBtn v-if="appConfig.links.instagram" class="midias-item" small icon @click="toLink(appConfig.links.instagram.link)">
                <VIcon color="#E1306C" small>fab fa-instagram</VIcon>
              </VBtn>

              <VBtn v-if="appConfig.links.twitter" class="midias-item" small icon @click="toLink(appConfig.links.twitter.link)">
                <VIcon color="#E1306C">fab fa-twitter</VIcon>
              </VBtn>
            </div>

          </div>

        </div>

        <div class="section--routes">

          <div class="footer-column">
            <div class="collumn-section m-b-16">
              <div class="collumn-section__header">
                <span class="set-title">{{$t('footer.appsStore')}}</span>
              </div>
              <div class="collumn-section__content-list">
                <button class="redirect-links" @click="toLink(appConfig.ios.linkStore)">
                  <span class="text-caption text-bold-thin text-gray">{{$t('all.ios')}}</span>
                </button>

                <button class="redirect-links" @click="toLink(appConfig.android.linkStore)">
                  <span class="text-caption text-bold-thin text-gray">{{$t('all.android')}}</span>
                </button>
              </div>
            </div>

            <div v-if="false" class="collumn-section">
              <div class="collumn-section__header">
                <span class="set-title">{{$t('footer.devWorks')}}</span>
              </div>
              <div class="collumn-section__content-list">

                <RouterLink class="redirect-links text-caption text-bold-thin text-gray" to="/integrations" v-bind="$attrs">
                  <span>{{$t('footer.integrations')}}</span>
                </RouterLink>

                <RouterLink class="redirect-links text-caption text-bold-thin text-gray" to="/developers" v-bind="$attrs">
                  <span>{{$t('footer.developers')}}</span>
                </RouterLink>
              </div>
            </div>
          </div>

          <div class="collumn-section">
            <div class="collumn-section__header">
              <span class="set-title">{{$t('footer.institucional')}}</span>
            </div>
            <div class="collumn-section__content-list">

              <RouterLink class="redirect-links text-caption text-bold-thin text-gray" to="/about" v-bind="$attrs">
                <span>{{$t('footer.aboutUs')}}</span>
              </RouterLink>

              <div v-if="false" class="unavaible">

                <RouterLink class="redirect-links text-caption text-bold-thin text-gray" to="/workUs" v-bind="$attrs">
                  <span>{{$t('footer.workUs')}}</span>
                </RouterLink>

                <RouterLink class="redirect-links text-caption text-bold-thin text-gray" to="/successCases" v-bind="$attrs">
                  <span>{{$t('footer.successCases')}}</span>
                </RouterLink>

                <RouterLink class="redirect-links text-caption text-bold-thin text-gray" to="/press" v-bind="$attrs">
                  <span>{{$t('footer.press')}}</span>
                </RouterLink>

              </div>

              <RouterLink class="redirect-links text-caption text-bold-thin text-gray" to="/contact" v-bind="$attrs">
                <span>{{$t('footer.contact')}}</span>
              </RouterLink>
            </div>
          </div>

          <div class="collumn-section">
            <div class="collumn-section__header">
              <span class="set-title">{{$t('footer.solutions')}}</span>
            </div>
            <div class="collumn-section__content-list">

              <RouterLink class="redirect-links text-caption text-bold-thin text-gray" to="/solutions/patients" v-bind="$attrs">
                <span>{{$t('footer.toPatients')}}</span>
              </RouterLink>

              <RouterLink class="redirect-links text-caption text-bold-thin text-gray" to="/solutions/doctors" v-bind="$attrs">
                <span>{{$t('footer.toDoctors')}}</span>
              </RouterLink>

              <RouterLink class="redirect-links text-caption text-bold-thin text-gray" to="/solutions/companies" v-bind="$attrs">
                <span>{{$t('footer.toCompanies')}}</span>
              </RouterLink>

              <RouterLink class="redirect-links text-caption text-bold-thin text-gray" to="/prices" v-bind="$attrs">
                <span>{{$t('footer.pricesPlans')}}</span>
              </RouterLink>
            </div>
          </div>

          <div class="collumn-section">
            <div class="collumn-section__header">
              <span class="set-title">{{$t('footer.contents')}}</span>
            </div>
            <div class="collumn-section__content-list">

              <RouterLink class="redirect-links text-caption text-bold-thin text-gray" to="/helpCenter" v-bind="$attrs">
                <span>{{$t('footer.helpCenter')}}</span>
              </RouterLink>

              <RouterLink v-if="false" class="redirect-links text-caption text-bold-thin text-gray" to="/podcast" v-bind="$attrs">
                <span>{{$t('footer.podcast')}}</span>
              </RouterLink>

              <RouterLink class="redirect-links text-caption text-bold-thin text-gray" to="/blog" v-bind="$attrs">
                <span>{{$t('footer.blog')}}</span>
              </RouterLink>
            </div>
          </div>

          <div v-if="false" class="routerLinks">
            <!-- <div><a href="/">{{$t('all.press')}}</a></div>
            <div><a href="/">{{$t('all.recent')}}</a></div>
            <div><a href="/">{{$t('all.status')}}</a></div>
            <div><a href="/">{{$t('all.ask')}}</a></div>
            <div><a href="/">{{$t('all.support')}}</a></div> -->
          </div>

        </div>

      </div>

      <div class="footer-rights">
        <span class="text-caption text-bold-thin text-gray text-upper"><strong>SAUVER</strong> - data tech for healthcare</span>
        <span class="text-caption text-bold-thin text-gray text-upper">{{$t('all.allRight')}}</span>
      </div>

    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import '@design';

  .app-footer--home {
    @include flexbox(column, nowrap, center, flex-start);
    width: 100%;
    height: fit-content;
    min-height: 300px;
    margin: 0;
    padding: 0;
    background-color: $soft-gray-1 !important;
    box-sizing: border-box;

    @include for-phone-only {
      width: 100vw;
    }

    @include for-desktop-up {
      align-items: center;
      // border: 1px solid red;
    }

    .footer-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: center;
      height: 100%;
      width: fit-content;
      max-width: 1300px !important;
      background-color: $soft-gray-1;

      margin: 0 0 24px 0;
      // border: 1px solid red;

      @include for-desktop-up {
        max-width: 1200px;
      }

      // border: 1px solid blue;

      .footer--wrapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: center;
        height: 100%;
        width: fit-content;
        margin: 0;
        padding: 0 32px;

        @include for-phone-only {
          max-width: 100vw;
          // border: 1px solid red;
        }

        @include for-desktop-up {

        }

        .section--enterprise {
          @include flexbox(column, nowrap, flex-start, flex-start);
          min-height: 150px;
          min-width: 250px;
          max-width: 250px;
          padding: 16px 0;
          text-align: left;
          // border: 1px solid red;

          @include for-phone-only {
            max-width: 100%;
            @include flexbox(column, nowrap, center, flex-start);
            margin: 32px 0 0 0;
          }

          @include for-desktop-up {

          }

          .enterprise--brand {
            min-width: 100px;
            padding: 16px 0;
            margin: 0;
            // border: 1px solid red;

            @include for-phone-only {
              margin: 0 0 16px 0;
              padding: 8px 0;
            }

            .encapsuled-link {
              padding: 0;

              @include for-phone-only {
                max-width: 120px;
                padding-bottom: 20px;
              }

              .image-brand {
                max-height: 40px;
                width: auto;
                object-fit: cover;
                margin: 0 0 0 -4px;

                @include for-phone-only {
                  align-self: flex-start;
                  max-height: 32px;
                }
              }

            }
          }

          .enterprise-address {
            margin: 0 0 24px 0;
            min-width: 320px;
            // border: 1px solid red;

           @include for-phone-only {
              margin: 0;
              min-width: 220px;
              text-align: center;
            }
          }

          .midias-content {
            width: fit-content;
            height: inherit;
            @include flexbox(row, nowrap,  center, space-between);
            background: transparent;
            margin: 0;
            padding: 8px 0;

            @include for-phone-only {
              // display: none;
            }

            .midias-item {
              width: 24px;
              height: 24px;
              // object-fit: contain;
              margin: 0 8px 0 0;
            }
          }
        }

        .section--routes {
          @include flexbox(row, nowrap, flex-start, flex-start);
          flex: 1 1 0;
          width: 100%;
          height: 250px;
          padding: 32px 16px;
          // border: 1px solid red;

          @include for-phone-only {
            display: none;
          }

          .collumn-section {
            @include flexbox(column, nowrap, flex-start, flex-start);
            min-width: 120px;
            height: 100%;
            margin: 0 32px 0 0;
            padding: 0;

            &__header {
              margin: 0 0 10px 0;
              .set-title {
                @include text(14px, 500, 1.35, $color-text, normal);
                letter-spacing: 0.5px;
              }
            }

            &__content-list {
              @include flexbox(column, nowrap, flex-start, flex-start);
              width: 100%;

              .redirect-links {
                text-decoration: none;
                margin: 0 0 8px 0;

                span {
                  color: rgba($dark-gray, .7) !important;
                  text-decoration: none;

                  &:hover {
                    color: rgba($dark-gray, .9) !important;
                    transition: all .2s ease-in-out;
                  }
                }
              }
            }

          }

        }
      }

      .footer-rights {
        @include flexbox(row, nowrap, center, center);
        width: 100%;
        background: transparent;
        margin: 0;
        padding: 16px 0;
        border-top: 1px solid rgba($dark-gray, .4);

        @include for-phone-only {
          display: none;
          @include flexbox(column, nowrap, center, center);
          text-align: center;
        }

        @include for-tablet-portrait-up {
          & span:first-child {
            margin: 0 24px 0 0;
          }
        }

        .text-rights {
          @include text(14px, 500, 1.35, rgba($dark-gray, .7), uppercase);
          font-family: 'Museosans';
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 1px;
          text-align: left;
          text-transform: lowercase;
        }
      }
    }

  }

</style>
