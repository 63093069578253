<script>
import {appConfig} from '@src/config';
import 'vuetify/dist/vuetify.min.css';
// import '@src/design/ck-editor-content-styles.css';

const fcmI18n = require('@i18n/index');
let {languages} = fcmI18n;

export default {
  name: 'App',
  page() {
    return {
      // All subcomponent titles will be injected into this template.
      titleTemplate(title) {
        title = typeof title === 'function' ? title(this.$store) : title;
        return title ? `${title} | ${appConfig.title}` : appConfig.title;
      },
      meta: this.metaComputed,
      link: this.linkComputed,
    }
  },

  computed:{
    metaComputed() {
      let metaRet = [];
      metaRet.push({property: 'og:locale', content: this.$i18n.locale});
      for(let language in languages) {
        metaRet.push({property: 'og:locale:alternate', content: language});
      }
      return metaRet;
    },
    linkComputed() {
      let metaRet = [];
      if(this.$router.currentRoute) {
        metaRet.push({ rel: 'canonical', href: appConfig.baseUrl+'/'+this.$router.currentRoute.fullPath });
        metaRet.push({ rel: 'og:url', href: appConfig.baseUrl+'/'+this.$router.currentRoute.fullPath });
        for(let language in languages) {
          metaRet.push({ rel: 'alternate', href: appConfig.baseUrl+'/'+this.$router.currentRoute.fullPath+'?locale='+language, hreflang: language });
        }
      }
      return metaRet;
    }
  },
}
</script>
<template>
  <VApp id="app">
    <RouterView></RouterView>
  </VApp>
</template>



<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
