<script>
  export default {
    page: {
      title: 'Home',
      meta: [],
    },

    components: {},

    data: function () {
      return {

      }
    },

    methods: {

    }
  }
</script>

<template>
  <div>
    <div>
      Created
    </div>
    <div>
      <div>
        ASD asdasd
      </div>
      <div>
        Asdff fdfdf
      </div>
      <div>
        Asdff fdfdffdfdwe
      </div>
    </div>
  </div>
</template>

