<script>
// import NavBar from '@components/nav-bar'
import NavBar from '@layouts/navbar'
import Footer from '@layouts/footer'
// import { authComputed } from '@state/helpers'
import Version from '@layouts/version'

export default {
  components: { NavBar, Footer, Version },
  props: {
  },
  computed: {
  //  ...authComputed
  }
}
</script>

<template>
  <div :class="$style.layoutVisitant">
    <Version/>
    <NavBar :class="$style.navbar"/>

    <div :class="$style.container" >
      <slot />
    </div>

    <Footer :class="$style.footer"></Footer>

  </div>
</template>

<style lang="scss" module>
@import '@design';

.layoutVisitant {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;

  .navbar {
    flex: 0 0 auto;
  }

  .container {
    /*margin: 0 auto;*/
    // min-height: 90vh;
    flex: 1 1 auto;
    // min-height: 100%;
    min-height: fit-content;
    position: relative;
    // overflow-y: hidden;
    // border: 1px solid red;

    @include for-desktop-up {
      min-height: fit-content;
    }
  }

  .footer {
    position: relative;
    flex: 0 0 auto;
  }

}

</style>
