module.exports = {
  landing: {
    section1: {
      title: "Em breve algo completamente novo",
      txt2: "Aumente a produtividade, padronize processos, controle glosas e repasse com segurança, simplicidade e rapidez",
    },
  },
};



