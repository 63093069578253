// import store from '@state'
// import defaults from '@/src/router/defaults'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => lazyLoadView(import('@views/generic/home')),
  },
  {
    path: '/about',
    name: 'about',
    component: () => lazyLoadView(import('@views/visitant/about')),
  },
  // {
  //   path: '/logout',
  //   name: 'logout',
  //   meta: {
  //     authRequired: true,
  //     beforeResolve(routeTo, routeFrom, next) {
  //       let routeTmp = defaults.routeLogOut[store.getters['auth/loggedIn']]
  //       store.dispatch('auth/logOut')
  //       const authRequiredOnPreviousRoute = routeFrom.matched.some(
  //         (route) => route.meta.authRequired
  //       )
  //       // Navigate back to previous page, or home as a fallback
  //       next(
  //         authRequiredOnPreviousRoute
  //           ? {
  //               name: routeTmp || 'login',
  //             }
  //           : {
  //               ...routeFrom,
  //             }
  //       )
  //     },
  //   },
  // },
  {
    path: '/404',
    name: '404',
    component: () => lazyLoadView(import('@views/generic/_404')),
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    name: '*',
    // redirect: '404',
    component: () => lazyLoadView(import('@views/generic/_404')),
  },
]


const requireModule = require.context(
  '../views/tests/',
  false,
  /^((?!index|\.unit\.).)*\.vue$/
)
// const demoRoutes = [];
requireModule.keys().forEach((fileName) => {
  let file = fileName.substr(2).split('.')[0];
  if(file!=='index') {
    routes.push({
      path: '/tests/'+file+'/:id?',
      name: 'tests/'+file,
      component: () => lazyLoadView(import('@views/tests/'+file))
    });
  //  demoRoutes.push('tests/'+file);
  }

});
//
// routes.push({
//   path: '/demos',
//   name: 'demos',
//   component: () => lazyLoadView(import('@views/demos/index')),
//   meta: {
//     demoRoutes: demoRoutes
//   },
// });


// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView, params = {}) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require('@views/generic/_loading'),
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require('@views/generic/_timeout'),
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
}

export default routes;
