<script>
  import Layout from '@layouts/visitant/main-layout';
  export default {
    page: {
      title: 'Home',
      meta: [
      ],
    },

    components: {
      Layout,
    },

    data: function () {
      return {

      }
    },

    methods: {

    }

  }
</script>

<template>
  <Layout>
    <div>
      Created
    </div>
    <div>
      <div>
        ASD asdasd
      </div>
      <div>
        Asdff fdfdf
      </div>
      <div>
        Asdff fdfdffdfdwe
      </div>
    </div>
  </Layout>
</template>

