<script>
  import { getFirestore, collection, query, where, getDocs, orderBy, limit } from "firebase/firestore/lite";
  import {appConfig} from '@src/config';
  import {firebaseApp} from '@src/db';
  import Layout from '@layouts/visitant/main-layout';
  export default {
    page: {
      title: 'Home',
      meta: [
        { name: 'description', content: appConfig.description },
        { name: 'og:description', content: appConfig.description },
      ],
    },

    components: {
      Layout,
    },

    data: function () {
      return {
        appConfig: appConfig,

        isLoading: true,

        itemId: null,
        slug: null,

        form: {
          name: {},
          imgUrl: null,
          order: 0,
          active: true,
          txt: {},
          abstract: {},
          updatedAt: 0,
        },

        defaultForm: {
          name: {},
          imgUrl: null,
          order: 0,
          active: true,
          txt: {},
          abstract: {},
          updatedAt: 0,
        },

        listPosts: [],
      }
    },
    mounted: function () {
      this.loadForm();
    },

    methods: {

      async loadForm() {
        this.listPosts = [];

        try {
          const db = getFirestore(firebaseApp);
          let objs = await getDocs(query(collection(db, "blog"), where("active", "==", true), orderBy("updatedAt", "desc"), limit(3)));

          objs.forEach((obj)=>{
            if(obj && obj.data()) {
              let form = { ...this.defaultForm, ...obj.data()};
              if(typeof form.name !== 'object') {
                form.name = {};
              }
              this.listPosts.push(form);
            }
          });
        } catch(err) {
          console.log('err', err);
        }

        this.isLoading = false;
      },

      getDate(dateTime) {
        if(typeof dateTime.toDate==='function') {
          return dateTime.toDate().toLocaleString('pt-BR');
        }
        return dateTime.toLocaleString('pt-BR');
      },

      toPost(item) {
        this.$router.push({path: `/post/${item.slug}`});
      },

      toRouter (path) {
        this.$router.push({ path: path })
      },
    }

  }
</script>

<template>
  <Layout>
    <div>
      mounted
    </div>
    <div v-for="(item, index) in listPosts" :key="index">
      {{$trdb(item.name)}}
    </div>
  </Layout>
</template>

