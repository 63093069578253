<script>
  const appConfig = () => import('@src/config');
  const Locale = () => import('@layouts/locale');

  export default {
  components: {
    Locale,
  },

  data: function () {
    return {
      appConfig: appConfig,
      onSolutions: false,
      isShowMenuMobile: false,
    }
  },

  computed: {
    // ...authComputed,
    routeName: function () {
      return this.$router.currentRoute.name
    },

    patientsGuard() {
      return this.routeName !== 'solutions/patient';
    },

    signInGuard() {
      return this.routeName === 'solutions/doctors' || this.routeName === 'prices';
    },
  },

  async mounted () {
    this.$eventHub.on('menuClicked', ()=>{
      this.isShowMenuMobile = false;
    });
  },

  async beforeDestroy() {
    this.$eventHub.off('menuClicked');
  },

  methods: {

    showLutions() {
      this.onSolutions = !this.onSolutions;
    },

    toRouter (path) {
      this.onSolutions = false;
      this.$router.push({ path: path })
    },

    changeMenu() {
      this.isShowMenuMobile = !this.isShowMenuMobile
    },

    awayShow() {
      this.isShowMenuMobile = false;
    },

    resolveSignin() {
      this.toRouter('/client/register');
      // if(this.routeName==='solutions/doctors' || this.routeName==='prices') {
      //   // console.log('login in app for patients')
      // }
    },

    resolveLogin() {
      if(this.routeName !== 'solutions/doctors' || this.routeName !== 'solutions/companies') {
        console.log('login in app for patients')
        this.toRouter('/client/login');
        return;
      }

      switch(this.routeName) {
        case 'solutions/doctors':
          this.toRouter('/client/login');
          break;
        case 'solutions/companies':
          this.toRouter('/company/login');
          break;
      }
      console.log('login', this.routeName)
    },
  },
}
</script>

<template>
  <nav class="app-navbar--home">
    <div class="navbar--inner">

      <div class="app-navbar--home__container">

        <div class="app-brand-content">
          <RouterLink class="encapsuled-link" to="/" v-bind="$attrs">
            <!-- <img class="image-brand" :src="require('@assets/images/img-navbar-logo@3x.png')" alt="logo" /> -->
            <picture>
              <source media="(max-width: 580px)" :srcset="require('@assets/images/img-navbar-logo.png')">
              <source media="(min-width: 1200px)" :srcset="require('@assets/images/img-navbar-logo@3x.png')">
              <img class="image-brand" :src="require('@assets/images/img-navbar-logo@2x.png')" height="75px" width="130px" alt="logo">
            </picture>
          </RouterLink>
          <span class="memo">data tech for health care</span>
        </div>

        <div class="routes-navigation navigation--disabled">

          <button class="nav-button button--soft" :class="{'button--matched':routeName==='home'}" @click="toRouter('/')">
            <span class="button-label">{{$t('navBar.home')}}</span>
          </button>

          <button class="nav-button button--soft" :class="{'button--matched':routeName==='about'}" @click="toRouter('/about')">
            <span class="button-label">{{$t('navBar.about')}}</span>
          </button>

          <button class="nav-button button--soft" :class="{'button--matched':routeName==='solutions'}" @click="showLutions">
            <span class="button-label">{{$t('navBar.solutions')}}</span>
            <v-icon class="button-icon icon--rigth" color="#5a5a5a" small>{{onSolutions?'mdi-chevron-up':'mdi-chevron-down'}}</v-icon>
          </button>

          <button class="nav-button button--soft" :class="{'button--matched':routeName==='prices'}" @click="toRouter('/prices')">
            <span class="button-label">{{$t('navBar.prices')}}</span>
          </button>

          <button class="nav-button button--soft" :class="{'button--matched':routeName==='blogs'}" @click="toRouter('/blogs')">
            <span class="button-label">{{$t('navBar.blogs')}}</span>
          </button>

          <button class="nav-button button--soft" :class="{'button--matched':routeName==='contactUs'}" @click="toRouter('/contact')">
            <span class="button-label">{{$t('navBar.contactUs')}}</span>
          </button>
        </div>


        <div class="access-content">
          <button class="button-secondary m-r-16" @click="resolveLogin">
            <span class="button-label text-upper">{{$t('navBar.logIn')}}</span>
          </button>

          <button class="app-button button--softBlue button--signin" @click="resolveSignin">
            <span class="button-label text-red">{{$t('navBar.signIn')}}</span>
          </button>
        </div>

        <div class="web-locale">
          <Locale />
        </div>

        <v-menu
          content-class="mobile-menu"
          bottom
          :close-on-click="true"
          min-width="200px"
        >
          <template v-slot:activator="{ on, attrs }">
            <button
              class="mobile-menu__buttom button--soft"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="button-icon">mdi-menu</v-icon>
            </button>
          </template>

          <v-list class="mobile-menu__list">

            <v-list-item>
              <button class="menu-button button--soft" :class="{'button--matched':routeName==='solutions/patients'}" @click="toRouter('/solutions/patients')">
                <v-icon class="button-icon icon--left" small>mdi-account-outline</v-icon>
                <span class="button-label">{{$t('navBar.forVisitants')}}</span>
              </button>
            </v-list-item>

            <v-list-item>
              <button class="menu-button button--soft" :class="{'button--matched':routeName==='solutions/doctors'}" @click="toRouter('/solutions/doctors')">
                <v-icon class="button-icon icon--left" small>mdi-account-circle-outline</v-icon>
                <span class="button-label">{{$t('navBar.forClients')}}</span>
              </button>
            </v-list-item>

            <v-list-item>
              <button class="menu-button button--soft" :class="{'button--matched':routeName==='solutions/companies'}" @click="toRouter('/solutions/companies')">
                <v-icon class="button-icon icon--left" small>mdi-hospital-building</v-icon>
                <span class="button-label">{{$t('navBar.forCompanies')}}</span>
              </button>
            </v-list-item>

            <v-list-item v-if="patientsGuard">
              <button class="app-button button--softBlue button--login" @click="resolveLogin">
                <span class="button-label">{{$t('navBar.logIn')}}</span>
              </button>
            </v-list-item>

          </v-list>
        </v-menu>

      </div>

      <div v-if="onSolutions" id="sol#00" class="app-navbar--home__solutions">
        <!-- <div class="solutions--header">
          <span class="text-body-3 text-bold text-gray">Nossas soluções para você:</span>
        </div> -->
        <div class="solutions--content">

          <div class="solution-tip">
            <span class="text-body-2 text-bold text-black">{{$t('navBar.solutionsTips.patient.title')}}</span>
            <span class="text-body-2 text-bold-thin text-gray">{{$t('navBar.solutionsTips.patient.text')}}</span>

            <RouterLink class="redirect-links text-caption text-bold-thin text-red" to="/solutions/patients" v-bind="$attrs">
              <v-icon class="m-r-8" color="#ff5052" small>mdi-link</v-icon>
              <span>{{$t('navBar.solutionsTips.patient.call')}}</span>
            </RouterLink>
          </div>

          <div class="solution-tip">
            <span class="text-body-2 text-bold text-black">{{$t('navBar.solutionsTips.doctor.title')}}</span>
            <span class="text-caption text-bold-thin text-gray">{{$t('navBar.solutionsTips.doctor.text')}}</span>

            <RouterLink class="redirect-links text-caption text-bold-thin text-red" to="/solutions/doctors" v-bind="$attrs">
              <v-icon class="m-r-8" color="#ff5052" small>mdi-link</v-icon>
              <span>{{$t('navBar.solutionsTips.doctor.call')}}</span>
            </RouterLink>
          </div>

          <div class="solution-tip">
            <span class="text-body-2 text-bold text-black">{{$t('navBar.solutionsTips.company.title')}}</span>
            <span class="text-caption text-bold-thin text-gray">{{$t('navBar.solutionsTips.company.text')}}</span>

            <RouterLink class="redirect-links text-caption text-bold-thin text-red" to="/solutions/companies" v-bind="$attrs">
              <v-icon class="m-r-8" color="#ff5052" small>mdi-link</v-icon>
              <span>{{$t('navBar.solutionsTips.company.call')}}</span>
            </RouterLink>
          </div>

        </div>
      </div>

    </div>
  </nav>
</template>


<style lang="scss" scoped>
// @import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import '@design';

.navigation--disabled {
  display: block;
  // @media screen and (max-width: $size-menu-actions+px) {
  //   display: none!important;
  // }
}

.fadebar-enter-to,
.fadebar-leave-active {
  animation: all 0.3s ease;
  height: 80px;
}
.fadebar-leave-to /* .fade-leave-active below version 2.1.8 */ {
  animation: all 0.3s ease;
  height: 0px;
}

.app-navbar--home {
  @include flexbox(column, nowrap, center, flex-start);
  height: fit-content;
  width: 100%;
  z-index: 100;

  @include for-desktop-up {
    justify-content: center;
  }

  .navbar--inner {
    @include flexbox(column, nowrap, flex-start, flex-start);
    width: 100%;
    // max-width: 1000px;
    height: 100%;
    min-height: $slin-height-navbar !important;
    // min-height: 80px;
    overflow: hidden;
    box-shadow: $v-shadow;
    z-index: 10;
    position: relative;
    border-bottom: 2px solid $soft-gray-1;
    border-radius: 0px;
    // border: 1px solid red;

    @include for-desktop-up {
      justify-content: center;
      max-width: 1300px;
    }
  }

  &__container {
    @include flexbox(row, nowrap, center, space-between);
    height: 100%;
    width: 100%;
    min-width: $size-content-width-min;
    max-height: 80px;
    position: absolute;
    top: 10px;
    box-shadow: none;
    z-index: 100;
    padding: 8px;
    margin: 0 auto;
    // border: 1px solid red;

    @include for-desktop-up {
      max-width: $max-width-navbar;
    }

    .app-brand-content {
      min-width: 100px;
      padding: 0;
      margin: 0 16px;
      // border: 1px solid red;

      .encapsuled-link {
        padding: 8px !important;
        @include flexbox(row, nowrap, center, center);

        .image-brand {
          max-height: 75px;
          max-width: 130px;
          // border: 1px solid red;
          // margin: auto;

          @media screen and (max-width: $size-company-menu-max+px) {
            align-self: flex-start;
            max-height: 52px;
          }
        }
        @media screen and (max-width: $size-company-menu-max+px) {
          min-width: 160px;
          padding-bottom: 20px;
        }
      }

      .memo {
        display: none;
        @include text(14px, 400, 1em, $color-primary, normal);
        font-family: 'MuseoSans';
        letter-spacing: 0.5px;
        margin: 0 0 0 16px;
      }
    }

    .routes-navigation {
      @include flexbox(row, nowrap, center, center);
      justify-self: center;
      height: inherit;
      min-width: 250px;
      margin: 0 auto;
      padding: 0 8px;
      // border: 1px solid red;

      @include for-phone-only {
        display: none;
      }

      .button--matched {
        border-radius: 0px;
        border-bottom: 2px solid $blue-scale-2 !important;

        .button-label {
          color: $blue-scale-2 !important;
        }
      }

      .nav-button {
        margin: 0 8px;
        border-radius: 0px;
        border-bottom: 2px solid rgba($color-text, 0);
        transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

        &:hover {
          cursor: pointer;
          // border-bottom: 2px solid rgba($color-text, 1);
          .button-label {
            color: rgba($color-text, 1);
          }
        }

        .button-label {
          @include text(12px, 600, 1em, $color-text, uppercase);
          color: rgba($color-text, 0.7);
          letter-spacing: 0.5px;
          font-family: 'Roboto';
        }
      }

    }

    .access--disabled {
      display: none !important;
    }

    .access-content {
      @include flexbox(row, nowrap, center, space-between);
      margin: 0 24px 0 0;
      min-width: 100px;

      @include for-phone-only {
        display: none !important;
      }

      .button--login {
        margin: 0 4px;
        border-radius: 6px;

        .button-label {
          @include text(12px, 600, 1em, $color-text, uppercase);
          letter-spacing: 0.5px;
          // font-family: 'Source Sans Pro';
        }
      }

      .button--login {
        // background-color: rgba($blue-scale-2, 0.1);
        margin: 0 32px 0 0;

        &:hover {
          // background-color: rgba($color-secondary, 0.2);
          cursor: pointer;
          transition: all .3s ease-in-out;

          .button-label {
            color: rgba($color-text, 1);
          }
        }

        .button-label {
          color: rgba($color-text, 0.8);
        }
      }
    }

    .web-locale {
      display: none;
      // @include flexbox(row, nowrap, center, space-between);
      margin: 0 24px;

      @include for-phone-only {
        display: none;
      }
    }

  }

  &__solutions {
    @include flexbox(row, nowrap, center, center);
    height: 80px;
    width: 100%;
    min-width: $size-content-width-min;
    position: relative;
    top: 0px;
    box-shadow: none;
    z-index: 20;
    padding: 8px;
    margin: 0 auto;
    transition: all .4s cubic-bezier(0.215, 0.610, 0.355, 1);

    .solutions--content {
      @include flexbox(row, nowrap, center, flex-start);

      .solution-tip {
        @include flexbox(column, nowrap, flex-start, flex-start);
        margin: 0 16px;

        .redirect-links {
          text-decoration: none;

          span {
            color: rgba($color-secondary, 1) !important;
            text-decoration: none;

            &:hover {
              color: rgba($color-secondary, .8) !important;
              transition: all .2s ease-in-out;
            }
          }
        }
      }
    }

    // border: 1px solid red;
  }
}

.mobile-menu {
  display: none;

  @include for-phone-only {
    display: block;
    border-radius: 10px;
    padding: 0;
  }

  &__buttom {
    display: none;

    @include for-phone-only {
      display: block;
      height: 40px;
      width: 40px;
      min-width: 40px;
      margin: 0 24px;
    }
  }

  &__list {

    .menu-button {
      border-bottom: 1px solid $soft-gray-blue;
      border-radius: 0px;
      width: 100%;

      &:hover {
        // background-color: rgba($color-secondary, 0.2);
        cursor: pointer;

        .button-label {
          color: rgba($blue-scale-2, 1);
        }
      }

      .button-icon {
        color: rgba($blue-scale-2, 1);
      }

      .button-label {
        @include text(12px, 600, 1em, rgba($color-text, 0.7), lowercase);
        letter-spacing: 0.5px;
      }
    }

    .button--login {
      width: 100%;
    }
  }
}
</style>
