let {translateDb, i18nConfig, languages} = require('@i18n');
// const _ = require('lodash');
import {eventHub} from "@src/eventHub";

export default {

  install (Vue) {
    Vue.mixin({
      computed: {
        $isDark: function() {
          return this.$vuetify.theme.dark;
        },
      }
    });
    Vue.prototype.$eventHub = eventHub; // Global event bus
    Vue.prototype.$trdb = function (obj) {
      if(this.$i18n && this.$i18n.locale) {
        const t = translateDb(this.$i18n.locale, obj);
        if(t) {
          return t;
        }
      }
      return translateDb('pt-BR', obj);
    };

    Vue.prototype.$trdbLocale = function () {
      if(this.$i18n && this.$i18n.locale) {
        return (languages[this.$i18n.locale] || {}).database;
      }
      return (languages[i18nConfig.fallbackLocale] || {}).database;
    };

  /*  Vue.prototype.$showMsg = function ({title='', text=null, timer=2500}={}) {
      this.$swal({
        toast: true,
        position: 'top-end',
        icon: 'success',
        title: title,
        text: text,
        showConfirmButton: false,
        timer: timer
      });
    };

    Vue.prototype.$showWarning = function ({title='', text=null, timer=3500}={}) {
      if(!title && !text) {
        title = this.$t('form.updateSuccess');
      }
      this.$swal({
        toast: true,
        position: 'top-end',
        icon: 'warning',
        title: title ,
        text: text,
        showConfirmButton: false,
        timer: timer
      });
    };

    Vue.prototype.$showSuccess = function ({title='', text=null, timer=2500}={}) {
      if(!title && !text) {
        title = this.$t('form.updateSuccess');
      }
      this.$swal({
        toast: true,
        position: 'top-end',
        icon: 'success',
        title: title ,
        text: text,
        showConfirmButton: false,
        timer: timer
      });
    };

    Vue.prototype.$showError = function ({title='', text=null, timer=4500}={}) {
      if(!title && !text) {
        title = this.$t('form.error.update');
      }
      this.$swal({
        toast: true,
        position: 'top-end',
        icon: 'error',
        title: title,
        text: text,
        showConfirmButton: false,
        timer: timer
      });
    };

    Vue.prototype.$showConfirm = async function ({title='', text=null}={}) {
      if(!title && !text) {
        title = this.$t('form.confirmQuestion');
      }
      let result = await this.$swal({
        title: title,
        text: text,
        showCancelButton: true,
        confirmButtonText: this.$t('form.confirmQuestionYes'),
        cancelButtonText: this.$t('form.confirmQuestionCancel')
      });
      return !!result.value;

    };
    Vue.prototype.$showPrompt = async function ({title='', text=null, input='text',
                                                  confirmButtonText=this.$t('form.confirmQuestionYes'),
                                                  cancelButtonText=this.$t('form.confirmQuestionCancel'),
                                                  inputAttributes = {},
                                                  inputValue=''

                                                }={}) {
      if(!title && !text) {
        title = this.$t('form.promptQuestion');
      }
      let result = await this.$swal.fire({
        title: title,
        text: text,
        input: input,
        inputAttributes: inputAttributes,
        showCancelButton: true,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        inputValue: inputValue,
        inputValidator: ()=>{return false;}
      });
      // console.log('result', result);
      if(result.isConfirmed) {
        return result.value;
      }
      return false;
    };

*/

    Vue.prototype.$fcmGetDark = function () {
      let isDark = typeof window!=='undefined'?window.localStorage.getItem('darkMode'):null;
      if(isDark==='true') {
        return true;
      }
      if(isDark==='false') {
        return false;
      }
      return true; // Setando dark mode como default
      // isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      // return !!isDark;
    };

    Vue.prototype.$fcmSetDark = function (isDark) {
      if(typeof window!=='undefined' && window.localStorage) {
        window.localStorage.setItem('darkMode', isDark?'true':'false');
      }
      if(this.$vuetify && this.$vuetify.theme) {
        if(this.$vuetify.theme.dark!==isDark) {
          this.$vuetify.theme.dark = isDark;
        }
      }
    };


    // Obs: extends Error não funciona com o Babel
    Vue.prototype.JwGetError = function(message, code=''){
      const err = new Error(message);
      err.code = code;
      return err;
    };
    Vue.prototype.$money2Float = function (num) {
      if (num === "") {
        num = 0;
      } else {
        num = num.replace("R$ ", "");
        num = num.replace(/\./g, "");
        num = num.replace(",", ".");
        num = parseFloat(num);
      }
      return num;
    };
    Vue.prototype.$sortByTimeStamp = function(items, field){
      return items.sort((a, b) => {
        if(a?.[field] && b?.[field]) {
          return b?.[field]?.seconds - a?.[field]?.seconds;
        }
        else if(a?.[field]) {
          return 1;
        }
        else if(b?.[field]) {
          return -1;
        }
        else {
          return 0;
        }
      });
    };

    Vue.prototype.$fcmClearAndReload = function() {
      if(window.navigator && navigator.serviceWorker) {
        navigator.serviceWorker.getRegistrations()
          .then(function(registrations) {
            for(let registration of registrations) {
              registration.unregister?.();
            }
            window.location.reload();
          });
      }
      else {
        window.location.reload();
      }
    }
    //
    // Vue.prototype.moment = moment;
    // Vue.prototype._ = _;
  }
}
