import '@src/db.js';
import Vue from 'vue';
import App from './App.vue';
import i18n from "@src/plugins/i18n";
import pluginGlobal from '@src/plugins/global.js';
import router from "@src/router";
import vuetify from "@src/plugins/vuetify";
const fcmI18n = require('@i18n');

Vue.config.productionTip = false;

import 'nprogress/nprogress.css';
import '@fortawesome/fontawesome-free/css/all.css'
import "@mdi/font/css/materialdesignicons.css";

// import VueSweetalert2 from 'vue-sweetalert2';
// import '@src/design/sweetalert2Theme.scss';
// Vue.use(VueSweetalert2);
// import '@src/design/sweetalert2Theme.scss';

Vue.use(pluginGlobal);

new Vue({
  router,
  vuetify,
  i18n,
  beforeMount() {
    const locale = this.$i18n.locale;
    if(fcmI18n.languages[locale]) {
      this.$vuetify.lang.current = fcmI18n.languages[locale].vuetify;
    }
  },
  render: h => h(App),
}).$mount('#app');
